import sidePicture from "images/login/side-image.webp";
import Image from "next/image";
import { useRouter } from "next/router";
import { ReactNode } from "react";

import { AppLayout } from "shared/Layout";
import { LoginForm } from "shared/LoginForm";
import { ResetPasswordForm } from "shared/LoginForm/ResetPasswordForm";
import { fetchSettings } from "shared/SettingsProvider/settings.helper";
import { CACHE_ONE_DAY } from "utils/config.utils";
import { withApp } from "utils/with-app.utils";
import { fetchTranslations } from "utils/with-i18n.utils";
import { fetchSEO, withSeo } from "utils/with-seo.utils";

export const LoginPage = () => {
  const {
    query: { reset, email },
  } = useRouter();

  const isResettingPassword = !!reset && !!email;

  return (
    <>
      <section className="flex flex-col items-center lg:flex-row">
        <div className="flex basis-2/3 flex-col grow items-center px-8 my-12 w-full lg:mt-32 lg:mb-72">
          {isResettingPassword ? (
            <ResetPasswordForm token={reset as string} />
          ) : (
            <LoginForm />
          )}
        </div>
        <aside className="aspect-square relative w-full lg:max-w-xl lg:h-screen">
          <Image
            alt="Login page illustration"
            src={sidePicture}
            objectFit="cover"
            placeholder="blur"
            priority
            layout="fill"
            sizes="(max-width: 1024px) 100vw, 33vw"
          />
        </aside>
      </section>
    </>
  );
};

LoginPage.getLayout = (page: ReactNode, pageProps: any) => (
  <AppLayout withBanner {...pageProps}>
    {page}
  </AppLayout>
);

export async function getStaticProps() {
  const seo = await fetchSEO("login");
  const translations = await fetchTranslations(["authentication", "marquee"]);

  return {
    props: { translations, seo, settings: await fetchSettings() },
    revalidate: CACHE_ONE_DAY,
  };
}

export default withApp(LoginPage, [withSeo]);
